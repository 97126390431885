/* Most places are recommending font-display: optional; but it's not working right

Also font weights set below aren't necessarily the actual weights, just set like this for selection
*/
@font-face {
  font-family: "Lexend Deca";
  src: url("/fonts/lexend-deca-variable.woff2") format("woff2");
  font-weight: 250 550;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fira Code";
  src: url("/fonts/fira-code.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
