@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  @apply bg-highlight text-white;
}

.grecaptcha-badge {
  @apply hidden #{!important};
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(0.65rem + 0.3vw);
  @screen smmax {
    font-size: calc(0.75rem + 0.3vw);
  }
}

:root {
  --highlight: theme(colors.tertiary);
  --highlight-two: theme(colors.quinary);
  --highlight-three: theme(colors.quaternary);
  --header-height: 100px;
}

button[type="submit"] {
  @apply w-full;
}

main {
  @apply overflow-hidden;
}

a,
.href-style {
  @apply ease-in-out duration-300 cursor-pointer;
}

body {
  @apply bg-gray-900 transition-all ease-in-out duration-300 font-light font-p text-base text-gray-200 max-w-full first:mt-0 last:mb-0;
}

p {
  @apply my-3 tracking-wide;
}

p:first-of-type {
  @apply mt-0;
}

strong {
  @apply font-bold;
}

ul > li {
  &::before {
    content: "";
    @apply absolute bg-primary rounded-full w-1 h-1 left-1 top-1/2 -translate-y-1/2;
  }
}

ul,
ol {
  @apply my-3;
  & ul,
  & ol {
    @apply my-2;
  }
  & li {
    @apply relative pl-5 mb-1;
  }
}

ol > li {
  counter-increment: levelOne;
  &::before {
    @apply absolute font-bold text-highlight left-0;
    content: counter(levelOne) ". ";
  }
  & ol li {
    @apply pl-7;
    counter-increment: levelTwo;
    &::before {
      content: counter(levelOne) "." counter(levelTwo) ". ";
    }
    & ol li {
      @apply pl-4;
      counter-increment: levelThree;
      &::before {
        content: counter(levelThree, lower-alpha) ". ";
      }
      & ol li {
        counter-increment: levelFour;
        &::before {
          content: counter(levelFour, lower-roman) ". ";
        }
      }
    }
  }
}

li.notion-to_do {
  &.checked {
    &::before {
      @apply bg-success #{!important};
    }
  }
  &.unchecked {
    &::before {
      @apply bg-error #{!important};
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-gray-100 font-h font-bold mt-0 mb-3;
}

h1 {
  @apply text-headingXl;
}

h2 {
  @apply text-headingLg;
}

h3 {
  @apply text-headingBase;
}

h4,
h5,
h6 {
  @apply text-headingSm;
}

h2 + *,
h3 + *,
h4 + * {
  @apply mt-0;
}

hr {
  @apply border-t border-gray-300 my-6;
  & * {
    @apply mt-0;
  }
}

figure {
  @apply my-4;
  & > * {
    @apply my-0;
  }
  & figcaption {
    @apply text-gray-500 text-sm mt-2;
  }
}

img {
  @apply my-0;
}

pre {
  @apply text-gray-200 bg-gray-800 overflow-x-auto my-4 rounded px-3 py-2;
}

code {
  @apply text-highlight font-normal;
  overflow-wrap: break-word;
  &:after {
    content: "\`";
  }
  &:before {
    content: "\`";
  }
}

pre code {
  @apply bg-transparent border-0 rounded-none p-0 font-normal;
  -webkit-text-fill-color: initial;
  &:before,
  &:after {
    content: none;
  }
}

video {
  @apply my-4;
}

table {
  @apply w-full table-auto text-left my-4 text-sm;
  & th,
  & td {
    @apply p-2;
  }
  & th {
    @apply align-bottom;
  }
  & td {
    @apply align-top;
  }
  & thead,
  & tfoot {
    @apply bg-gray-700 font-bold border-b border-gray-800;
  }
  & tbody {
    & tr {
      @apply border-b border-gray-800 last:border-b-0;
    }
  }
}

.table-highlight-first-col {
  & tr > *:first-child {
    @apply bg-gray-700 font-bold;
  }
}

.notion-has-row-header {
  & tr td:first-of-type {
    @apply bg-gray-800 font-bold px-3 border-b border-b-white;
  }
}

blockquote {
  @apply font-light italic border-l-2 border-gray-800 my-3 pl-3;
  quotes: "\201C""\201D""\2018""\2019";
  & p {
    &:first-of-type:before {
      content: open-quote;
    }
    &:last-of-type:after {
      content: close-quote;
    }
  }
}

/**
 * nprogress
 */
#nprogress {
  @apply pointer-events-none;
}

#nprogress .bar {
  @apply bg-highlight fixed top-0 left-0;
  z-index: 1031;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  @apply block absolute right-0 h-full opacity-100;
  width: 100px;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  @apply block fixed;
  top: 15px;
  left: 15px;
  z-index: 1031;
}

#nprogress .spinner-icon {
  @apply animate-spin border-2 rounded-full border-highlight border-b-0 border-r-0;
  width: 18px;
  height: 18px;
}

.nprogress-custom-parent {
  @apply overflow-hidden relative;
  & #nprogress .spinner,
  #nprogress .bar {
    @apply absolute;
  }
}

/**
 * Swiper - Overrides. Main styles imported in app
 */
// for smooth transitions
.swiper-ease-linear {
  & .swiper-wrapper {
    @apply ease-linear #{!important};
  }
}

// for swiper navigation component
.swiper-nav-prev,
.swiper-nav-next {
  @apply cursor-pointer text-xl;
  &.swiper-button-disabled {
    @apply opacity-50 cursor-auto;
  }
}
